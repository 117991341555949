import React from "react"; 
import '../../../App.css';


export function Wolds () {

    return (
      <div>
        {/* <MetaTags>
           <title>Matt Feeney Photography</title>
           <meta name="description" content="Matt Feeney Photography - Photo Gallery" />
         </MetaTags> */}
       <section>
          <div id="recent-work-container" className="row justify-content-center">
            <div className="col-10 text-center pt-4">
              <h2>Wolds</h2>
            </div>
            <img src="../../../images/projects/wolds/bears.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/adverts.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/barrow.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/hetty.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/hetty-2.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/just-jill.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/cross.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/shop.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/old-car.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/old-car-inside.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/old-car-driving.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/cat.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/cafe-flowers.jpg" className="col-7 col-lg-5 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/peppers.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/telephone-swing.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/book-sale.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/book-lady.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/church-pillow.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/church-pillows.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/staff-parking.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/closed-on-bank-holiday.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/laundrette.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/tent.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/chairs.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/facepainting.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/coffee.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/man-on-bench.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/old-couple.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/pet-shop.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/outside-tables.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/old-man-pipe.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/use-other-door.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/handy-mai.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/book-sale-2.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/shop-2.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/private-coffee-room.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/coffee-room.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/closed-postoffice.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/closed-postoffice-2.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/closed-postoffice-3.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/free-apples.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/free-apples-selfie.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/cash-only.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/closed-postoffice-casitor.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/dont-block-drive.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/micks-fish-and-chips.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/micks-fish-and-chips-2.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/micks-fish-and-chips-3.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/key-hole.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/chains.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/chains-2.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/cow-wall.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/charity-shop-caistor.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/playground.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/petrol-station-caistor.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/petrol-station-caistor-2.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/petrol-station-caistor-3.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/petrol-station-caistor-4.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/onions.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/potatoes.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/1st-place.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/cake.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/drinks.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/chairs-2.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/home-charity-sale.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/ridin-dirty.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/proms-in-the-park.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/chairs-on-lawn.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />

            <img src="../../../images/projects/wolds/don-noble.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/bookstore.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/antique-fruit.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/open-pram.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/antique-face.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/antique-chair.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/staff-only.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/trinkets.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/nip-slip.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/binocs.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/purple.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/green-figure.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/bottles.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/sitting-cakes.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />
            <img src="../../../images/projects/wolds/saucy.jpg" className="col-10 col-lg-9 mt-5" width="100%" loading="lazy" />

            
          </div>
         </section>
      </div>
    );
  }
