import React, { Component } from "react";
import '../App.css';
import Map from '../components/map';
import lozad from 'lozad';

function Project3() {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
        <p>One style of photography that always jumps out at me is urbex. For those of you who do not know what it is, urbex (or ubexing) is short for urban exploring. It's about exploration of man-made structures, which are usually abandoned.</p>
        <p>If you're interested in finding out more here's a couple of sites I use:</p>
        <p>
          <a className="button-link"  href="https://www.28dayslater.co.uk/" rel="noopener noreferrer" target="_blank">28dayslater</a> <br />
          <a className="button-link"  href="http://www.ukurbex.co.uk/" rel="noopener noreferrer" target="_blank">ukurbex</a>
        </p>
        <p>The urbex community is great and very friendly. People offer advice on how to access a abandoned sites and list when the best time to go is. If you ever need to find out more information, just ask a question in the forums and someone will always answer.</p>
        <p>After too long admiring other peoples urbex work, I decided to try it for myself. I knew of a perfect spot, the old RAF hospital at Nocton Hall. I used to visit there when I was a teenager, but with being a while since I have last visited it seemed wise to drag my good friend <a className="button-link" href="https://www.instagram.com/azarrrr/">Aaron</a> along (he used to visit this spot when he was younger too).</p>
        <p><b>Some Background</b></p>
        <p>RAF Hospital Nocton Hall was a 740-bed RAF hospital serving the predominantly RAF personnel based at the large number of RAF Stations in the area. It was used by forces personnel, their families and local civilians until it closed on 31 March 1983.</p>
        <p>The hospital can be found to the east of Nocton.</p>
        <Map zoom={13} lat={53.164798} lng={-0.409655} />
        <p>It has been over 10 years since we last visited the hospital and it was completely different. Nature had started to reclaim the hospital and it was beautiful!</p>
        <img className="pb-4 lozad" width="100%" src="../images/3/twin-windows.jpg" alt="Nature growing through two windows" />
        <img className="pb-4 lozad" width="100%" src="../images/3/corner-window.jpg" alt="Nature growing through a corner window" />
        <img className="pb-4 lozad" width="100%" src="../images/3/long-hallway.jpg" alt="Long creepy hallway" />
        <p>I am glad we decided to visit during the day, even with light some parts were a little creepy...</p>
        <img className="pb-4 lozad" width="100%" src="../images/3/dark-hallway.jpg" alt="Dark creepy hallway" />
        <img className="pb-1 lozad" width="100%" src="../images/3/window.jpg" alt="Old window" />
        <p><small>Download from: <a className="button-link" href="https://unsplash.com/photos/4yDFLmxRoZs" rel="noopener noreferrer" target="_blank">Unsplash</a></small></p>
        <img className="pb-4 lozad" width="100%" src="../images/3/broken-door.jpg" alt="Old broken enterance doors" />
        <img className="pb-4 lozad" width="100%" src="../images/3/dark.jpg" alt="Door with a dark hallway" />
        <p>We only managed to explore around half of the hospital, due to the how thick the the trees and bushes had grown through the middle of the site. We're planning to go back later this year and see if we can explore the top half of the site.</p>
        <img className="pb-4 lozad" width="100%" src="../images/3/bye.gif" alt="Bye" />
      </div>
    );
  }

export default Project3;
