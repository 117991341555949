import React, { Component } from "react";
import '../App.css';
import Map from '../components/map';
function Project1(){
  return (
    <div>
        <p><small>Photo by: <a className="button-link" href="https://www.instagram.com/robbie_cumming/" rel="noopener noreferrer" target="_blank">Robbie Cumming</a></small></p>
        <div className="">
          <p>My name is Matt and I'm a web developer from Lincoln (UK). <small>Sorry if it sounds like I'm introducing myself as a contestant on a game show.</small> For those interested, I'm a c# .net developer.</p>
          <p><small>A quick side note, this is the first time I've blogged. So please give me some slack, it might be rough to begin with, but stick with me. It will get good, I promise!</small></p>
          <p>Most people I speak to have no idea where Lincoln is, or have never heard of it (even though it's an entire county). If you're one of those people, here's a map: </p>
          <Map zoom={9} lat={53.2201817} lng={-0.5316637} />

          <p>I've always been interested in photography, but I never gave it much time. I started off with one camera, a Canon 100d. A great beginners camera, but I didn't really know how to use it. I would take it out every now and again, but because I didn't know how to take photos I slowly stopped.</p>
          <p>The start of 2017 everything changed. An old friend got in contact, he was upgrading his Canon 7D Mark I to the new Mark II. My friend was curious to know if I wanted to buy it, upgrading my 100d to a big boy camera.</p>
          <p>With not using my 100d that much, I should have said no and saved my money. Thankfully, that's not what happened. I foolishly scraped some money together and bought it! I'm glad I did, buying the camera reignited my passion for photography.</p>
          <p>I was lucky with how my purchase played out, I got a great deal and it was what I needed to motivate me. I strongly recommend <b>avoiding</b> what I did!</p>
          <div className="row mb-2">
            <div className="col-12 text-center">
              <h4><i>You don't need an expensive camera to take great shots!</i></h4>
            </div>
          </div>
          <p>Buying an expensive camera when you're not an active photographer can be a costly mistake. If you're wanting to get into photography I recommend finding a cheap entry level DSLR or using the camera on your phone.</p>
          <p>To start with, use what ever you can afford and learn the basics. I'd recommend checking out a good beginner tutorial like this one:</p>
          <p><small><a className="button-link" rel="noopener noreferrer" target="_blank" href="https://artofvisuals.com/the-basics-of-photography-introduction-to-photography-tutorials/">ArtOfVisuals - THE BASICS OF PHOTOGRAPHY</a></small></p>
          <p>With my new found passion for photography I found myself going out more and more. It started off maybe once or twice a month, but before I knew it I was going out every opportunity I could!</p>
          <p>I soon exhausted a lot of the local spots and started going on trips further and further away from home. I'm going to share my trips in future posts. Sharing where I've been and what photography projects I'm working on are the main reasons why I've created this site.</p>
          <p>Along side taking more photos, I started to get involved with instragram and other platforms. I'm not going to talk about that now, I'll cover what platforms I use and why in a future post.</p>
          <p>For now that's it from me, but I'll be back soon with another post!</p>
          <img className="pb-4" width="100%" src="../images/1/bye.gif" alt="bye animated" />

        </div>
    </div>
  );
}

export default Project1;
