import React from "react";
import {useState, useEffect} from "react";
import BlogPostList from "../../BlogPosts/blogPostList";
//const MetaTags = require ('react-meta-tags');
import PageMissing from '../../components/pageMissing';
import { TwitterShareButton } from 'react-share';
let projectsJson = require('../../data/projects.json');

interface BlogPost{
  "name": string,
  "id": string,
  "component": string,
  "coverImage": string,
  "headerImage": string,
  "description": string,
  "date": string,
  "published":boolean
}

function BlogPost(props: any){
  const [project, setProject] = useState<BlogPost | null>(null);

  useEffect(() =>{
    window.scrollTo(0, 0);
    setProject(findProject(props.match.params.id))
  },[props])

  const findProject = (id: string) => {
    for(var i = 0; i < projectsJson.length; i++)
    {
      if(projectsJson[i].id === id)
      {
        return projectsJson[i];
      }
    }
  }

  return (
    <div>
      {!project && <PageMissing />   }
      {project && 
      <React.Fragment>
        <div className="post-heading-container">
          <div className="text-center">
            <h1>{project!.name}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
          <div className="text-container px-sm-3">
            <p><small>Posted: <b>{project!.date}</b></small></p>
            </div>
          </div>
          <div className="col-12">
            <div className="text-center text-container px-sm-3">
              <img className="pb-1" width="100%" src={"../images" + project!.headerImage} alt="Post header" />
            </div>
          </div>
          <section aria-label="post-content" className="text-container px-sm-3">
            <div className="col-12">
              <BlogPostList project={ project!.component } />
            </div>
          </section>
      </div>
      </React.Fragment>
    }  
    </div>
  );
}

export default BlogPost;
