import React, { Component } from "react";
import '../App.css';
import lozad from 'lozad';

function Project12 () {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
        <p>After hearing from a friend, who had bought tickets to go see Blink 128 play in Barcelona. I discovered that gig tickets are a lot cheaper outside of the UK and decided to get some for me and my girlfriend.</p>
        <p>Some other friends had also heard about the tickets and a big ol' group of us would be heading off to Spain.</p>
        <p>For most occasions these days I tend to carry my Leica m10 with a 28mm lens. I find a 28mm gives me a lot of room in the frame to play with and my m10 allows me to be efficient with how I shoot.</p>
        <p>I've found this setup works for me on most occasions: days out shooting street, project work or social settings with friends. I even accidently ended up shooting a pole dancing event and and the combo held out (more on that in another post).</p>
        <p>My point is that this set up never gets in my way and allows me to reliably capture the moments I'm seeing without drawing much attention.</p>
        <p><strong>Why didn’t I take my Leica?</strong></p>
        <p>I was warned by several people that pick pockets and muggings were something to be aware of Barcelona and to be careful.</p>
        <p>As I would travelling with large group, visiting tourist spots and no doubt sampling a few cervezas along the way. I didn’t like the idea of having to keep watch all the time.</p>
        <p>Knowing I didn’t feel comfortable taking my Leica, I started weighing up my other options.</p>
        <p>The only other digital cameras I own are a Instax Evo Mini and my phone. I wanted something more dedicated than my phone and so I started to have a look at cheap digital cameras. </p>
        <p>I know a lot of people rave about Ricoh GR cameras, but soon into looking I discovered that they’re quite expensive and I couldn't justify the cost (knowing I would probably sell it once I was back).</p>
        <p>Then the idea hit me that maybe I could challenge myself to purely shooting film for the trip, after all I do have a hefty collection of film cameras to pick from.  It didn’t take me long to decide on a potential combination of my Olympus AF10 and my Canon AE1 (with a 28mm lens). </p>
        <p>The two would be more than capable, but that put my total cameras to 3 and my bag said no.</p>
        <p>Around this time <a href="https://www.instagram.com/dean_scutt" target="_blank">Dean Scott</a> mentioned that he had recently bought a Canon Zoom XL for £20. Reasons being that he was traveling to somewhere where there was a chance of being mugged (so cheap and expendable were a must), but also that the camera produced quality images. </p>
        <p>Hearing that Dean had the same criteria and trusting his opinion, I jumped on eBay and noticed there were a few listed in near perfect condition and all for around £30. I decided to take punt on one (knowing that if the stories were true), the Zoom XL could be the one.</p>
        <p>It didn’t take long for the camera to arrive and straight out of the box I was impressed. The camera was the right kind of chunky, felt great in the hand and its build was reassuring.</p>
        <p>I was pretty happy so far, but you never know with film cameras. So I slapped in a test roll and headed out to check the camera was okay.</p>
        <p><strong>The results</strong></p>
          <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/test1.png" alt="Confident" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/test2.png" alt="Confident" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/test3.png" alt="Confident" />
        </div>
        <div className="text-center">
          <img className="pb-2 lozad" width="100%" src="../images/12/test4.png" alt="Confident" />
        </div>
        <p>Okay so first impressions, the camera did not disappoint!</p>
        <ul>
          <li>The camera turns on and is ready to go almost instantly.</li>
          <li>The view finder is positioned on the edge and I was able to use it like a range finder.</li>
          <li>The weight/construction were reassuring and when pressing the shutter the camera didn’t hang about to focus.</li>
          <li>The photos came out sharp and the metering held up well.</li>
        </ul>
        <p>It didn’t take me long into reviewing the scans to know that my search was over and that I had the camera I was looking for.</p>
        <p><b>My final gear selection:</b></p>
        <ul>
          <li>Canon Zoom XL</li>
          <li>12 rolls of asorted colour film:<br /> <i>
            (Kodak Max 400, Colour Plus, expired Porta 400 and Fuji c200)</i></li>
          <li>Spare batteries for the canon</li>
          <li>Instax Evo Mini</li>
          <li>5 packs of Instax Mini film</li>
          <li>Tin for Instax prints</li>
          <li>Long weekend sling bag (not in the photo)</li>
        </ul>
        <div className="col-12  m-0 p-0">
          <div className="text-center">
            <img className="pb-2 lozad" width="70%" src="../images/12/gear.png" alt="Gear" />
          </div>
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/plane.png" alt="bk" />
        </div>        
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/bk.jpg" alt="bk" />
        </div>   
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/Jono-airport-hug.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/boarding.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/hands.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/seats.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/Jono-plane.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/a-airport.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/stairs.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/jade-hat.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/Jono-hug.jpg" alt="bk" />
        </div>  
            <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/drinks.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/fingers.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/home.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/Empty-Table.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/ball.jpg" alt="bk" />
        </div> 
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/jade-beach.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/pointing-lady.jpg" alt="bk" />
        </div> 
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/glen-wall.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/toilet-selfie.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/ribbon.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/yellow-wall.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/blur.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/grave.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/metro.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/tip.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/green-suit.jpg" alt="bk" />
        </div>

       

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/old-shadows.jpg" alt="bk" />
        </div>

       
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/guys-gig.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/jono-gig.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/tom-was-right.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/post-blink.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/door-ajar.jpg" alt="bk" />
        </div>

      
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/bike-message.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/red-car.jpg" alt="bk" />
        </div>
      

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/green-fountain.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/watching-coffee.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/hat.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/no-driver.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/lady-waiting.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/grave-2.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/man-hands.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/moped.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/hole.jpg" alt="bk" />
        </div>

        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/glaciar.jpg" alt="bk" />
        </div>
        <div className="text-center">
          <img className="pb-3 lozad" width="100%" src="../images/12/metro-2.jpg" alt="bk" />
        </div>

        <p className="pt-3"><b>Wrapup</b></p>
        <p>I had great fun in Barcelona and the Canon ZoomXL was a fantasic companion. It took a moment to get used to its wider 38mm focal length, but it didn't take me long.</p>
        <p>Do I wish it was a little bit wider? Of course I do, but I've learnt to work round limitations and its good for your creativity to mix things up.</p>
        <p>Batteries are normally always a concern with cameras right? Not for the Canon ZoomXL!</p>
        <p>I packed plenty of batteries for the 5 days (not knowing how long they would last), but the first set we're still showing as full by the time I got home.</p>
        <p>The only negative I can say about the camera is that on a couple occasions, after finsihing a roll it would be fussy about loading in a new one.</p>
        <p>I've not read much about this issue, so it could be a problem with my copy or that I'm slightly special and I've been loading it wrong.</p>
        <p>All in all I'm really happy with my Canon ZoomXL and it'll definitely be staying in my collection.</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/12/bye.gif" alt="Bye" />
        </div>
      </div>
    );  
  }

export default Project12;
