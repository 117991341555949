import React, { Component } from "react";
import '../App.css';
import lozad from 'lozad';

function Project6() {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
      <p>During my time I've taken photos all over: some on the ground, some in the sky, some in cities and some in the country. Nearly all of my shots have been of fairly still situations; where I can take as many shots as I need, till I'm happy I've got the photo I want.</p>
      <p>Over the last few weeks I've been trying my camera at street photography, and let me just say... it's <b>hard!</b></p>
      <p>All of the comforts I'm used to are all gone. Taking as many shots as I want... nope, moving and trying different angles... no chance, maybe using a different lens... yeah right!</p>
      <p>On top of losing out on my much loved comforts, there's an added bonus with street photography... stage fright! Sounds silly I know, but it's true. It can genuinely be a bit frightening taking photo of a random stranger in the street. A lot runs through your head, and if they spot you it makes it even harder to raise your camera to take the shot.</p>
      <div className="text-center">
        <img className="pb-1 lozad" width="60%" src="../images/6/i-see-you.jpg" alt="I see you" />
        <p><small><b>I see you</b> (1/640 f/2.8 ISO 800 20mm)</small></p>
      </div>
      <p>Depending on the situation and how confident you are, there's a couple different ways you can approach it.</p>
      <p>The first and maybe the easiest is to shoot your shot from the hip. It can be a tricky technique to get right (even with a tilty flippy screen), but shooting from the hip takes a lot of the fright away. You can improve the chances of getting a good shot by making sure you have all your settings dialed in and set your focus to roughly where in the frame your subject would be.</p>
      <img className="pb-1 lozad" width="100%" src="../images/6/shoot-from-the-hip.jpg" alt="shoot from the hip" />
      <p>I've seen plenty of photographers take great shots from the hip, but most forget to mention that they've taken buckets of shots to eventually get one good one.</p>
      <p>To begin with I found myself shooting from the hip a lot, but with little success I soon stopped, changing my approach to the opposite.</p>
      <p>I take on a balance of blending in, but at the same time make it obvious I'm taking a photo. I do this avoid disturbing the moment I'm trying to capture, but at the same time trying to avoid coming across sneaky (sneaky is not professional).</p>
      <p>It's a hard balance to get right and sometimes I get noticed, when I do I always try to give a warm smile and stay confident with my shot. If I get noticed before I've taken my shot, I'll guesture to my camera and ask if they mind. If they say no or clearly aren't happy, I smile and move on.</p>
      <p>If I get noticed after the shot I'll go and introduce myself and show them the photo, but if it's clear they're not too happy, I avoid any more upset and get myself out of there. It can be hard to read people and if I'm unsure about how they feel, I'll give them a little smile and carry on my way.</p>
      <p>On some occasions it's impossilbe to take a photo without being noticed. If you ever come across an oppotunity like this you either leave and instantly regret it, or you can be brave and speak to them.</p>
      <p>The first time this happened to me, I noticed a woman wearing a big fur coat sat on some stairs. She was facing the other way to the way I was walking. There was no way I could walk past her and turn round take to take a photo without her noticing.</p>
      <p>I could have just walk past, spun, taken the shot and walked off, but it wouldn't have felt right, it would have probably come across incredibly rude. Terrified I apporached the woman and asked her if she minded if I took her photo.</p>
      <p>What happened next blew me away, the lady was shocked and very flattered I wanted to take her photo! Her mood changed instantly, she was so happy and it threw me off guard.</p>
      <div className="text-center">
        <img className="pb-1 lozad" width="60%" src="../images/6/lady-sitting.jpg" alt="Lady Sitting" />
        <p><small><b>Gina Sitting</b> (1/1000 f/3.5 ISO 800 18mm)</small></p>
      </div>
      <p>After I took take my shot and showed it to her, Gina was impressed, so impressed she asked if I wouldn't mind taking another shot with her standing up and posing!</p>
      <div className="text-center">
        <img className="pb-1 lozad" width="60%" src="../images/6/lady-stood.jpg" alt="Lady Standing" />
        <p><small><b>Gina Standing</b> (1/1000 f/3.5 ISO 800 28mm)</small></p>
      </div>
      <p>I was in shock and forgot to pose her properly so the shots didn't turn out quite how I wanted them, but the experience was amazing! Gina was lovely and the fact she was flattered that I wanted to take her photo made me feel great that I was brave enough to speak to her.</p>
      <p>The photos below were taken using using what I've been talking about. Some were candid shots and others were taken with permission.</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/6/the-butcher.jpg" alt="The Butcher" />
          <p><small><b>The Butcher (Candid)</b> (1/1000 f/3.2 ISO 160 35mm)</small></p>
        </div>
        <img className="pb-1 lozad" width="100%" src="../images/6/order-up.jpg" alt="Order Up" />
        <p><small><b>Order Up (Candid)</b> (1/320 f/2.8 ISO 400 35mm)</small></p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/6/walk-of-shame.jpg" alt="Stumble" />
          <p><small><b>Stumble (Candid)</b> (1/1000 f/3.5 ISO 200 35mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/6/medic.jpg" alt="medic" />
          <p><small><b>Medic (Permission)</b> (1/250 f/2.8 ISO 125 24mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/6/old-friends.jpg" alt="Old Friends" />
          <p><small><b>Old Friends (Candid)</b> (1/500 f/2.8 ISO 320 18mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/6/waiting-for-the-call.jpg" alt="Waiting for the call" />
          <p><small><b>Waiting for the call (Candid)</b> (1/400 f/2.8 ISO 160 23mm)</small></p>
        </div>
        <img className="pb-1 lozad" width="100%" src="../images/6/waiting-game.jpg" alt="Waiting Game" />
        <p><small><b>Waiting Game (Candid)</b> (1/500 f/2.8 ISO 200 23mm)</small></p>
        <p>As you can probably tell, there's a noticable difference between the candid and the shots I asked for permission. This is because when ever people notice there's a camera, they tend to automatically go into a pose mode. </p>
        <p>There's nothing wrong with taking photos of people posing, but when you're trying to capture a moment, a story, it's not quite the same. It can be a little frustrating at times, but when all the hard work pays off and you manage to make a great photo, there's no better feeling.</p>
        <h5>I've learned a lot and here's some of my top tips:</h5>
        <p>Don't be afraid to ask to take a photo - worst they can say is no</p>
        <p>Always check your settings. I shoot manual, so with walking around and the sun moving, my settings can quickly be wrong. I tend to keep an eye out on when the light's changed or about to change and tweak my settings to match.</p>
        <p>A handy tip I picked up from <a className="button-link" href="https://www.instagram.com/bvstreet/" rel="noopener noreferrer" target="_blank">Bhadri Verduzco (bvstreet)</a>, always keep a faster shutter speed when shooting street. Bhadri recommends trying to stay close to 1/1000s to make sure your shots stay nice and sharp.</p>
        <p>Don't be afraid to take lots of shots. It's good to try things and experiment. I take lots of shots that I've never done before to see how they turn out. Sometimes the photo at the end isn't anything special, but on a few occasions I've ended up making a photo I would have never thought about.</p>
        <p>Never delete a shot unless your sure it's useless. I wait till I get my photos back on my computer before deciding their fate. I can't count how many times I've gone back to a photo; day's, weeks or even months after I made it and ended up with something I never even knew I had.</p>
        <p>Stop and take stock - you never know what's behind you or what might happen. It feels natural to keep on walking, but sometimes its good to stop and wait. Try and find somewhere to perch or sit and take a look at whats happening</p>
        <p>One of my best tips is to get out there! It's a numbers game; you need to be going out all the time and if you manage to take one photo you're proud of, then you're done well. There's a rule that you need to spend 10,000 hours practicing something to be a expert. I'm not sure how true it is, but I've certainly noticed the more I'm out shooting, the more confident I am and there's a noticiable difference in the quality of my photos.</p>
        <p>Right... I think that's enough rambling from me, go get your camera and go get out and make some photos!</p>
        <img className="pb-2 lozad" width="100%" src="../images/6/bye.gif" alt="Bye" />
      </div>
    );
  }

export default Project6;
