import React from "react"; 
import '../../../App.css';


function lostAndFound () {

    return (
      <div>
        {/* <MetaTags>
           <title>Matt Feeney Photography</title>
           <meta name="description" content="Matt Feeney Photography - Photo Gallery" />
         </MetaTags> */}
       <section>
          <div id="recent-work-container" className="row justify-content-center">
            <div className="col-10 text-center pt-4">
              <h2>Signs of life</h2>
            </div>
            <img src="../../../images/projects/signs-of-life/car.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/recent-work/lisa-birds.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="images/recent-work/icecream-people.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="images/recent-work/looking-at-shore.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="images/recent-work/pink.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="images/recent-work/guard.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="images/recent-work/protest.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="images/recent-work/dragon-ride.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="images/recent-work/yellow-slice.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="images/recent-work/green-stairs.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="images/recent-work/empty-chair.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
          </div>
         </section>
      </div>
    );
  }

export default lostAndFound;
