


export function RealWrasslin () {
    return (
<div>
    <div id="recent-work-container" className="row justify-content-center">
        <div className="col-10 text-center pt-4">
            <h6>REAL WRASSLIN'</h6>
        </div>
    </div>
    <div className="row justify-content-center">
        <img width="100%" className="col-11" src="../../../images/projects/real-wrasslin/in-light.jpg" loading="lazy" />
    </div>
    <div className="row justify-content-center">
        <img src="../../../images/projects/real-wrasslin/turning-up.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/video-guy.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/hard-stare.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/king-staring.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/king-vaulting.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/quick-kick.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/mid-kick.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/throwing-the-king.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/come-on.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/mid-air-throw.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/pre-jump.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/mid-air.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/grappling.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/winner2.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/celebrate.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/walk-it-off.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/another-entrance.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/high-five.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/cowboy.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/get-back-in.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/thrown-across.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/jump-slam.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/calling-out.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/jump-out.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/double-take-down.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/double-payback.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/kick-to-the-face.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/holding-two.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/intermission.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />  
        <img src="../../../images/projects/real-wrasslin/long-life-milk.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/dolla-walkin.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/dolla-face-crowd.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/dolla-face-crowd-stare.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/face-off-in-light.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/im-fine.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/ass-up.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/are-you-okay.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/both-down.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/winners.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/entrance.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/entrance-ring.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/entrance-ring-2.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/entrance-ring-3.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/entrance-ring-4.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/pick-up.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/walk-out-1.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/stare-down-crowd.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/in-blue-light.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/flex-in-crowd.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/flex-in-crowd-1.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/flying.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/flying-1.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/flip.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/a-chair.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/mounted.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/slamming-down.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/on-the-ropes.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/real-wrasslin/winner-hands-up.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
    </div> 
</div>
    )}