import React from "react";
import '../../App.css';
// const MetaTags = require('react-meta-tags');

import Banner from "../../components/banner/";

function contact (){
    return (
      <div>
        {/* <MetaTags>
           <title>Matt Feeney Photography</title>
           <meta name="description" content="Matt Feeney Photography - Contact" />
         </MetaTags> */}
         <Banner bannerStyle="contact" bannerText=""  />
         <div className="section pt-5 pb-5 dark text-center ">
            <h5 className="title">Please feel free to email me, I'm always happy to have a chat.</h5>
            <a className="btn btn-primary-dark mt-3 mb-4" href="mailto:hello@mattfeeneyphotography.co.uk">hello@mattfeeneyphotography.co.uk</a>
         </div>
      </div>
    );
}

export default contact;
