import * as React from "react";
import '../../App.css';
import {
  NavLink
} from "react-router-dom";
function PageMissing () {
    return (
      <div>
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="pt-5">Well this is awkward</h1>
            <p>I'm not sure how you got here, but here isn't anywhere...</p>
            <p>Click <NavLink to=""><button className="button-link">here</button></NavLink> to go home?</p>
          </div>
        </div>
      </div>
    );
  }

export default PageMissing;
