import React, {useState, useEffect} from "react";
import '../../App.css';
import {
  NavLink
} from "react-router-dom";
//const MetaTags = require('react-meta-tags');
import Banner from "../../components/banner/";

var projectsJson = require('../../data/projects.json');

interface Project{
  "name":string,
  "id":string,
  "component": string,
  "coverImage": string,
  "headerImage": string,
  "description": string,
  "date": string,
  "published":boolean
}

function blog () {

  const [projects, setProjects] = useState([]);
  const [projectsLoaded, setProjectsLoaded] = useState(0);
  const [loadLimit, setLoadLimit] = useState(10);

  useEffect(() =>{
    setProjects(projectsJson);
  },projectsJson);

    return (
      <div>
        {/* <MetaTags>
            <title>Matt Feeney Photography</title>
            <meta name="description" content="Matt Feeney Photography - Blog" />
        </MetaTags> */}
          <Banner bannerStyle="blog" bannerText=""/>
           <section aria-label="blog-posts" className="text-container grid px-sm-3 mt-3 mt-md-4">
               {projects
               .slice(0,loadLimit)
               .map((project:Project, i:number) => {
                  if(project.published){
                    if(projectsLoaded < loadLimit){
                        return (
                            <div key={project.id} className={"blog-post-container"}>
                                <div className="row">
                                  <div className="col-12 col-md-4">
                                    <img alt={"" + project.name} width="100%" src={"images/" + project.coverImage} loading="lazy"  />
                                  </div>
                                  <div className="col-12 col-md-8 pt-2 pt-sm-2 pt-md-0">
                                    <h4>{project.name}</h4>
                                    <p><small><b>{project!.date}</b></small></p>
                                    <p dangerouslySetInnerHTML={{ __html: project.description}} />
                                    <NavLink className="btn btn-primary-light" to={"/blog/" + project.id}>Read more</NavLink>
                                  </div>
                                </div>
                                {(i < projects.length -1 || i < loadLimit - 1)  &&
                                  <div className={"pt-4"}>
                                    <hr />
                                  </div>
                                }
                            </div>
                          );
                        }
                    }
                    return null
               })}
              {loadLimit < projects.length &&
                <div className="row">
                  <div className="col-12 text-center mt-5">
                      <a className="btn btn-primary-light" onClick={ () => setLoadLimit(loadLimit+10)}>More</a>
                  </div>
                </div>
              }
            </section> 
      </div>
    );
}

export default blog;
