


export function TakeMeToChurch () {
    return (
<div>
    <div id="recent-work-container" className="row justify-content-center">
        <div className="col-10 text-center pt-4">
            <h6>TAKE ME TO CHURCH</h6>
        </div>  
    </div>
    <div className="row justify-content-center">
        <img width="100%" className="col-11" src="../../../images/projects/take-me-to-church/crown-window.jpg" loading="lazy" />
    </div>
    <div className="row justify-content-center">
        <img src="../../../images/projects/take-me-to-church/man-sitting-with-empty-chairs.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        {/* <img src="../../../images/projects/take-me-to-church/windows-and-chairs.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" /> */}
        <img src="../../../images/projects/take-me-to-church/light-crack.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/pray-cards.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/candles.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/no-candles.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/church-open-welcome.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/three-crosses.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/tea-and-biscuits.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/holy-roses.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/empty-church.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/lit-candles.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/finding-peace.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/corner.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/cross-light.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/stain-glassed-light.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
        <img src="../../../images/projects/take-me-to-church/girl-writing.jpg"  className="col-11 col-lg-2 mt-5" width="100%" loading="lazy" />
    </div> 
</div>
    )}