import React, { Component } from "react";
import '../App.css';
import lozad from 'lozad';

function Project11 () {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
        <p>For as long as I can remember, every time I've gone out for the day or on a trip, I've ended up packing my biggest bag with lots of gear.</p>
        <p>I'm talking about: a main camera (sometimes a second body), a tripod, a drone, 2-3 extra lenses, filters for those lenses, filters for the drones camera and enough batteries to power a small city. Which all together help lead to one heavy bag and no real room for anything else I might need.</p>
        <p>Last October I booked a weekend away with some friends to go camping, and to make the most of the ease in social distancing. I hadn't seen my friends for over 6 months and I was looking forward to spending the weekend with them.</p>
        <p>The thought of actually seeing my friends in person got me thinking, It’s been months since I've seen them, and with everything going on, who knows when the next time might be.</p>
        <p>With thinking about how I normally see a trip as a way to get out and make some photos, I started to question if my usual motives would be right for this trip. It didn't take me long to come to the conclusion that photography needed to take a back seat this time.</p>
        <p>I could still bring a camera, but I needed to be less serious about photography and more in the moment with my friends. I decided to only pack my Canon AE-1, a 50mm lens and a few rolls of film (some black and white and some colour).</p>
        <p>With packing so little gear meant I could use a much smaller bag and still have more room for food and snacks!</p>
        <p>Having this moment of clarity was brilliant, I couldn’t believe I had’t thought of it before. I get to go out to make photos all the time, but only every so often do I get to go out with my friends on a trip. It doesn’t make sense to focus on photography when I’m out on one of the few occasions with my friends.</p>
        <p>Finding the balance can be tricky, as I feel there’s always this pressure as a photographer to create, but learning when to be less serious with your photography is a valuable skill to have.</p>
        <p>Right that's enough waffling from me, enjoy some photos!</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/confident.png" alt="Confident" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/car-park-snaps.png" alt="Car park snaps" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/lots-of-rain.png" alt="Lots of rain" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/downpour.png" alt="Downpour" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/the-trail.png" alt="The Trail" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/hillside-path.png" alt="Hillside Path" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/no-man-left-behind.png" alt="No man left behind" />
        </div>
        
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/snacks.png" alt="Snacks" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/checking-the-route.png" alt="Checking the route" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/group-haze.png" alt="Group Haze" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/andy-r.png" alt="Andy" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/hands-on.png" alt="Hands on" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/Aaron.png" alt="Aaron" />
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/alex.png" alt="Alex" />
        </div>

        <p className="pt-3"><b>Final thoughts...</b></p>
        <p>Learn to be apart of the moment and stop being so serious!</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/11/bye.gif" alt="Bye" />
        </div>
      </div>
    );  
  }

export default Project11;
