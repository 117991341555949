import React from 'react'

// const MapBox = ReactMapboxGl({
//   accessToken: "pk.eyJ1IjoibWF0dGZlZW5leSIsImEiOiJjamdxbzhsZjgwNDhuMndvNWw0emR1YXIwIn0.hnR2IV2almhZFzmeF8YJ4Q"
// });

interface Props{
  zoom:number,
  lat:number,
  lng:number
}

const Map: React.FC<Props> = ({
  zoom,
  lat,
  lng}
  : Props) => { 
  return (
    <div className = "map-container mb-2">
   
    </div>
  )
}

export default Map;