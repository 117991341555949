import React from "react";
import '../../App.css';

interface Props{
  bannerStyle: string,
  bannerText: string
}

const Banner: React.FC<Props> = ({
  bannerStyle, 
  bannerText}
  : Props) => {
    return(
    <div className={"banner text-center " + bannerStyle}>
      <div className="banner-container">
          <h1 className="banner-title align-middle">{bannerText}</h1>
      </div>
    </div>);
  }                                  

  export default Banner;