import React, { Component } from "react";
import '../App.css';
import lozad from 'lozad';

function Project10 () {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
        <p>During the first lockdown I used my one hour of outdoor exercise to go on walks to combat being stuck inside.</p>
        <p>I made these photos while out on my walks.</p>
        <p>These photos soon became my first project, and then my first book. If you would like to pick up a copy, you can grab one from <a className="button-link" href="https://www.blurb.co.uk/b/10148883-empty-spaces" target="_blank">here</a>.</p>
       <div className="row">
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pb-5 pt-5 lozad" width="95%" src="../images/10/1-1.jpg" alt="Two empty chairs" />
          </div>
         </div>
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pb-2 lozad" width="70%" src="../images/10/1-2.jpg" alt="Empty Cafe" />
          </div>
        </div>
        </div>
        <div className="row pt-5">
        <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pb-5 lozad" width="95%" src="../images/10/2-1.jpg" alt="Stacked chairs in an empty cafe" />
          </div>
         </div>
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pb-5 lozad" width="95%" src="../images/10/2-2.jpg" alt="Empty Cafe" />
          </div>
        </div>
        <div className="col-12 col-md-12 m-0 p-0">
          <div className="text-center">
            <img className="pb-5 lozad" width="97.5%" src="../images/10/3.jpg" alt="Empty table" />
          </div>
        </div>
        <div className="col-12 col-md-12 m-0 p-0">
          <div className="text-center">
            <img className="pb-0 lozad" width="97.5%" src="../images/10/4.jpg" alt="A wooden sign saying stay strong" />
          </div>
        </div>
        <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pb-0 pt-5 lozad" width="95%" src="../images/10/5-1.jpg" alt="Empty high street" />
          </div>
         </div>
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pb-0 pt-5 lozad" width="95%" src="../images/10/5-2.jpg" alt="Empty tables" />
          </div>
        </div>
        <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pb-0 pt-5 lozad" width="95%" src="../images/10/6-1.jpg" alt="An empty bus station" />
          </div>
         </div>
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pb-0 pt-5 lozad" width="95%" src="../images/10/6-2.jpg" alt="An empty bench in the bus station" />
          </div>
        </div>
        <div className="col-12 col-md-12 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="97.5%" src="../images/10/18.jpg" alt="A rainbow saying thank you" />
          </div>
        </div>
        <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="95%" src="../images/10/7-1.jpg" alt="An empty street" />
          </div>
         </div>
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="95%" src="../images/10/7-2.jpg" alt="Empty seats in a pub" />
          </div>
        </div>
        <div className="col-12 col-md-12 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="97.5%" src="../images/10/8.jpg" alt="Empty chairs and tables in a cafe" />
          </div>
        </div>
        <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="95%" src="../images/10/9-1.jpg" alt="An empty restaurant" />
          </div>
         </div>
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="95%" src="../images/10/9-2.jpg" alt="An abandoned table number at a empty table" />
          </div>
        </div>
        <div className="col-12 col-md-12 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="60%" src="../images/10/10.jpg" alt="A single hat left in a cafe" />
          </div>
        </div>
        </div>
        <div className="row pt-5">
            <div className="col-12 col-md-6 m-0 p-0">
              <div className="text-center">
                <img className="pt-5 pb-5 lozad" width="95%" src="../images/10/11-1.jpg" alt="An empty hair dressing chair" />
              </div>
            </div>
            <div className="col-12 col-md-6 m-0 p-0">
              <div className="text-center">
                <img className="lozad" width="70%" src="../images/10/11-2.jpg" alt="An empty chair in a mirror" />
              </div>
            </div>
        </div>
        <div className="row pt-5">
        <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="lozad" width="70%" src="../images/10/12-2.jpg" alt="An empty barbers chair" />
          </div>
         </div>
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="95%" src="../images/10/12-1.jpg" alt="An empty barbers" />
          </div>
        </div>
        <div className="col-12 col-md-12 m-0 p-0">
          <div className="text-center">
            <img className="pb-5 pt-5 lozad" width="60%" src="../images/10/13.jpg" alt="A closed sign" />
          </div>
        </div>
        <div className="col-12 col-md-12 m-0 p-0">
          <div className="text-center">
            <img className="pb-5 lozad" width="97.5%" src="../images/10/14.jpg" alt="An empty launderette" />
          </div>
        </div>
        <div className="col-12 col-md-12 m-0 p-0">
          <div className="text-center">
            <img className="pb-1 lozad" width="97.5%" src="../images/10/15.jpg" alt="A dark and empty restarant" />
          </div>
        </div>
        <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="95%" src="../images/10/16-1.jpg" alt="A till and empty shelves in a bakers" />
          </div>
         </div>
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="95%" src="../images/10/16-2.jpg" alt="McDonalds completely empty and in darkness" />
          </div>
        </div>
        <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="95%" src="../images/10/17-1.jpg" alt="An empty coffee shop"/>
          </div>
         </div>
         <div className="col-12 col-md-6 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="95%" src="../images/10/17-2.jpg" alt="An empty shopping centre" />
          </div>
        </div>
        <div className="col-12 col-md-12 m-0 p-0">
          <div className="text-center">
            <img className="pt-5 lozad" width="97.5%" src="../images/10/19.jpg" alt="A messaged stapled to a door, saying see you on the other side" />
          </div>
        </div>

       </div>      
      </div>
    );
  }

export default Project10;
