import React from "react"; 
import '../../../App.css';


export function Scampton () {

    return (
      <div>
        {/* <MetaTags>
           <title>Matt Feeney Photography</title>
           <meta name="description" content="Matt Feeney Photography - Photo Gallery" />
         </MetaTags> */}
       <section>
          <div id="recent-work-container" className="row justify-content-center">
            <div className="col-10 text-center pt-4">
              <h2>Scampton</h2>
            </div>
            <img src="../../../images/projects/scampton/1.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/2.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/3.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/4.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/5.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/6.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/7.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/8.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/9.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/10.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/11.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/12.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/13.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/14.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/15.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/16.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/17.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/18.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/19.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/20.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/21.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/22.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/scampton/23.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
          
          </div>
         </section>
      </div>
    );
  }
