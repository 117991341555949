import React, { Component } from "react";
import '../App.css';
import Map from '../components/map';
import lozad from 'lozad';

function Project5 () {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
        <p>After a successful camping trip in the Peak District, I was itching to get back out there. I regaled my adventures in the <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://photography.matt-feeney.co.uk/project/peak-district">Peak District</a> with some of my friends and they wanted to get involved. </p>
        <p>We through some ideas about and one that took our interest was Stickle Tarn in the Lake District. Stickle Tarn is a lake (tarn) on top of a mountain, with amazing views of all the surrounding valleys. </p>
        <p>Side note - Stickle Tarn isn't technically a "mountain", but it's not far off. In the UK anything over 600m (2,000 feet) is classed as a mountain. So it's more of an almost mountain and with it being my second hike, it's more than enough for me.</p>
        <Map zoom={13} lat={54.459395} lng={-3.101205} />
        <p>Looking for a campsite for our trip turned out to be incredibily easy, with a big of Googling we found out there's a campsite down the road from Stickle Tarn. <a className="button-link" href="https://www.nationaltrust.org.uk/holidays/great-langdale-campsite-lake-district" target="_blank" rel="noopener noreferrer">Great Langdale</a> campsite was perfect, it a short walk from our route and had all the facilities we could ever need.</p>
        <p>Plus with a view like this I don't think we could have picked a better campsite.</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/5/outside-tent.jpg" alt="View from outside my tent" />
          <p><small><b>View from my tent</b> (1/15 f/5.0 ISO 2000 35mm)</small></p>
        </div>
        <h5>The Route</h5>
        <p>The route was nice and straight forward; we would take a 5 miniute walk down the road to the bottom of Stickle Tarn, to a pub called <a className="button-link" href="https://www.nationaltrust.org.uk/sticklebarn-and-the-langdales/features/feasts-and-treats-at-sticklebarn-and-the-langdales" target="_blank" rel="noopener noreferrer">Sticklebarn</a>. From there it was a "simple" hike up to the top (473m), then doing the reverse to get back down. Of course stopping off at the pub, with how highly it's reviewed it would be rude not too?</p>
        <img className="pb-1 lozad" width="100%" src="../images/5/leaving-camp.jpg" alt="Leaving Camp" />
        <p><small><b>Leaving the campsite</b> (1/400 f/4.0 ISO 500 17mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/5/our-challenge.jpg" alt="Our Challenge" />
        <p><small><b>Our Destination - Stickle Tarn </b> (1/400 f/4.0 ISO 640 17mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/5/the-start-of-the-climb.jpg" alt="The start of the climb" />
        <p><small><b>Andy and Alex excited to get hiking</b> (1/400 f/4.0 ISO 1000 17mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/5/the-start-stone.jpg" alt="Start Stone" />
        <p><small><b>It begins</b> (1/320 f/4.0 ISO 1000 17mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/5/aaron-at-the-bottom.jpg" alt="Aaron at the bottom" />
        <p><small><b>Aaron take photos of the climb ahead</b> (1/400 f/4.0 ISO 1000 17mm)</small></p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/5/first-waterfall.jpg" alt="Waterfall" />
          <p><small><b>Waterfall - Long Exposure</b> (0.4 f/8.0 ISO 100 17mm)</small></p>
        </div>
        <img className="pb-1 lozad" width="100%" src="../images/5/slippy-moss.jpg" alt="Slippy Moss" />
        <p><small><b>Slippy Moss</b> (1/320 f/4.5 ISO 1250 30mm)</small></p>
        <p>As photogenic as the Moss was, it played havoc with us all day. Luckily we discovered how slippy it was early on and we didn't have any major accidents.</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/5/water-fall.jpg" alt="Waterfall" />
          <p><small><b>Waterfall</b> (1/320 f/4.0 ISO 1000 17mm)</small></p>
        </div>
        <img className="pb-1 lozad" width="100%" src="../images/5/dont-look-down.jpg" alt="Don't look down" />
        <p><small><b>Don't look down</b> (1/320 f/4.0 ISO 1250 17mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/5/photo-of-a-photo.jpg" alt="A shot the valley" />
        <p><small><b>Photo of a photo</b> (1/320 f/4.0 ISO 500 17mm)</small></p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/5/quick-stop-for-album-cover.jpg" alt="Waterfall" />
          <p><small><b>Quick stop for album cover</b> (1/320 f/4.0 ISO 1000 17mm)</small></p>
        </div>
        <img className="pb-1 lozad" width="100%" src="../images/5/aaron-wet.jpg" alt="Aaron a little wet" />
        <p><small><b>Aaron a little damp</b> (1/320 f/4.5 ISO 1000 30mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/5/andy-looking-sharp.jpg" alt="Andy looking sharp" />
        <p><small><b>Andy looking sharp</b> (1/320 f/8 ISO 1000 17mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/5/alex-wet.jpg" alt="Alex" />
        <p><small><b>Alex blending in</b> (1/320 f/8 ISO 1000 17mm)</small></p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/5/nearing-the-top.jpg" alt="Nearing the top" />
          <p><small><b>Nearing the top</b> (1/250 f/5.0 ISO 800 17mm)</small></p>
        </div>
        <img className="pb-1 lozad" width="100%" src="../images/5/the-lake.jpg" alt="The Top" />
        <p><small><b>The Top</b> (1/320 f/4.0 ISO 500 17mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/5/we-made-it.jpg" alt="The 4 of us at the top" />
        <p><small><b>Aaron, Andy, Alex and myself</b> (1/320 f/4.0 ISO 500 17mm)</small></p>
        <h5><b>We made it!</b></h5>
        <p>We were all excited to see the top, I don't think any of us had ever been so happy to see a lake before. The last leg of our hike was pretty hard, it involved a lot of scrambling over rocks and navigating fast flowing streams. All with plenty of rain and 50-60 mph winds trying to push us about. </p>
        <p>Thankfully the lake was shielded for the wind and gave us a nice restbite before having to battle with the elements to get back down.</p>
        <p>I'm glad I took plenty of shots on the way up because the return journey was pretty rough and I didn't get the chance to take any shots on the way back down.</p>
        <p><b>Final thoughts</b></p>
        <p>Apart from getting wet through, we all had a great time. The scenery was amazing, the weather could have been better, but we still had lots of fun. Plus with dealing with the wind and rain all day made our visit to the pub all the more special.</p>
        <p>If your interested in a challenging I'd highly recommend tackling Stickle Tarn.</p>
        <img className="lozad" width="100%" src="../images/5/bye.gif" alt="Bye" />
      </div>
    );
  }

export default Project5;
