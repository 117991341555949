import React from "react";
import '../../App.css';
import { NavLink } from "react-router-dom";
//const MetaTags = require('react-meta-tags');
import Banner from "../../components/banner/";

function about (){
  return (
    <div>
      {/* <MetaTags>
          <title>Matt Feeney Photography</title>
          <meta name="description" content="Matt Feeney Photography - About me" />
        </MetaTags> */}
        <Banner bannerStyle="about" bannerText=""/>
        <div className="text-container">
          <div className="row justify-content-center">
            <div className="col-9">
              <p className="pt-4">My name is <strong>Matthew Feeney</strong>, I'm a software engineer from Lincoln here in the <strong>UK</strong> and in my spare time I enjoy <strong>endlessly</strong> wondering the streets with a <strong>camera</strong>, trying to make <strong>photos</strong>.</p>
              <p>I mainly focus on <strong>street</strong> and <strong>documentation</strong> photography, but I enjoy getting out and making frames of things that speak to me (not worrying too much about labels).</p>
              <p>I primarily shoot with a <strong>rangerfinder</strong> with a fixed <strong>35mm</strong> lens (I find it works best for me day to day), but I do also shoot with <strong>film</strong> cameras and my <strong>phone</strong> as well.</p>
              <p>You can't beat <strong>mixing</strong> it up and I find it helps <strong>flex</strong> those creative muscles.</p>
            </div>
          </div>
        </div>
        
    </div>
  );
}
export default about;
