import React, { Component } from "react";
import '../App.css';
import lozad from 'lozad';

function Project9() {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
        <p>straighten that horizon</p>
        <p>talk about the problem... how i see a lot of great photos, but they've forgotten to straighten up their lines</p>
        <p>below is an example of a wonky horizon</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/9/peaks-not-straight.jpg" alt="Lincoln in Snow" />
          <p><small><b></b></small></p>
        </div>
        <p>talk about how it slops right to left</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/9/peaks-not-straight-line.jpg" alt="Lincoln in Snow" />
          <p><small><b></b></small></p>
        </div>
        
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/9/peaks-straight-line.jpg" alt="Lincoln in Snow" />
          <p><small><b></b></small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/9/peaks-header.jpg" alt="Lincoln in Snow" />
          <p><small><b></b></small></p>
        </div>
        <p>frustrating seeing great images that have a tilt, as it's a simple thing to fix</p>

        <p>maybe show another example, maybe a portrait or square image?</p>
        <p>show an example of how to use the tools in lightroom to check and straighten an image</p>
        <p>show how to use the rules/guides in photoshop as an alternative to lightroom</p>
        <p>some times there isn't a clear horizon, so best fit so it looks right</p>
        <p>sometimes images need to have the vertical lines striaghten to correc an image</p>

        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/9/umbrella-not-straight.jpg" alt="Sparks" />
          <p><small><b></b></small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/9/umbrella-not-straight-line.jpg" alt="Sparks" />
          <p><small><b></b></small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/9/umbrella-middle-line.jpg" alt="Sparks" />
          <p><small><b></b></small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/9/umbrella-straight.jpg" alt="Sparks" />
          <p><small><b></b></small></p>
        </div>
      </div>
    );
  }

export default Project9;
