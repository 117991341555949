import React, { Component } from "react";
import '../App.css';
import Map from '../components/map';
import lozad from 'lozad';

function Project4 (){
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div>
        <p><small>Download from: <a className="button-link" href="https://unsplash.com/photos/s1ctEgFj9io" rel="noopener noreferrer" target="_blank">Unsplash</a></small></p>
        <p>I travel all over taking photos, venturing further and futher from home. While finding great places to explore, I always find my self running into the same problem, there's not enough time in the day.</p>
        <p>After a bit of thinking it hit me, why don't I go camping? I'll be able to spend a full day exploring without worrying about having to travel back home, camping would also add some more adventure to my trip.</p>
        <h5>Planning trip tips</h5>
        <ul>
          <li>Research somewhere with plenty of interesting places to see and photograph.</li>
          <li>Find a campsite near by and make sure to check out the reviews.</li>
          <li>If it's your first time camping, try to find somewhere not too far from home.</li>
          <li>Keep an eye on the weather and make sure to have a plan B.</li>
        </ul>
        <p>For me and my camping companion (<a className="button-link" href="https://www.instagram.com/azarrrr/" target="_blank" rel="noopener noreferrer">Aaron Lambert</a>) it was our first time arranging a camping trip and we decided it was best not to go too far from home.</p>
        <p>A perfect place for us was the Peak District and with the help of Go Outdoors <a className="button-link" href="http://www.gooutdoors.co.uk/blog/top-10-campsites-peak-district/" rel="noopener noreferrer" target="_blank">Top 10 Campsites Peak District Guide</a> we found our campsite.</p>
        <p>Located on the outside of the village of Hope, <a className="button-link" href="http://www.lanesidecaravanpark.co.uk/" rel="noopener noreferrer" target="_blank">Laneside Caravan Park</a> was a great choice as there's lots of great photo opportunities and the highly reviewed Old Hall Hotel Pub was a 2 minute walk away.</p>
        <Map zoom={12} lat={53.345475} lng={-1.735369} />
        <h5>The Route</h5>
        <p>Originally Aaron and I had planned a few rough ideas on where we wanted to go, but after chatting to the owners of the campsite, we decided to take on the route below:</p>
        <img className="pb-1 lozad" width="100%" src="../images/4/the_route.jpg" alt="Our walking route" />
        <p><small>For those who are wondering where I get my nails done, sorry but that's not my thumb holding the map.</small></p>
        <p>Here's a breakdown of the route:</p>
        <ul>
          <li>A short walk from our campsite to Castleton</li>
          <li>Castleton up to Mam Tor</li>
          <li>Along the ridge line to Lose Hill, stopping at Hollins Cross and Back Tor</li>
          <li>And finally from Back Tor down to where we started in Hope</li>
        </ul>
        <p>It seemed simple enough, but the map forgot to mention one thing... the distance. Turns out the route is over 20 miles! </p>
        <p>Good thing we had lots of amazing views to keep our minds off it.</p>
        <img className="pb-1 lozad" width="100%" src="../images/4/valley.jpg" alt="Village in a valley" />
        <p><small><b>Hope Valley</b> (1/400 f/4.0 ISO 100 20mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/4/cow.jpg" alt="Cow in a field" />
        <p><small><b>One of the locals</b> (1/400 f/4.5 ISO 200 26mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/4/train-tracks.jpg" alt="Train tracks in trees" />
        <p><small><b>Tracks through the trees</b> (1/500 f/4.0 ISO 500 17mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/4/bikes-valley.jpg" alt="Cyclists in a green valley" />
        <p><small><b>Cyclists</b> (1/400 f/4.0 ISO 200 22mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/4/side-hill.jpg" alt="Hillside over looking a valley" />
        <p><small><b>Back Tor</b> (1/400 f/5.0 ISO 200 38mm)</small></p>
        <p>We finished our hike with plenty of time to spare (<small>and it was too early to go to the pub</small>), so we decided to take a quick drive to explore Ladybower reservoir. I'd always wanted to visit, and with Ladybower only being a 5 minute drive from our campsite, it seemed like the perfect opportunity.</p>
        <img className="pb-1 lozad" width="100%" src="../images/4/light-hole.jpg" alt="Light shining through a thick green trees" />
        <p>
          <small><b>Light finds a way</b> (1/400 f/4.0 ISO 1000 17mm)</small><br />
          <small>Download from: <a className="button-link" href="https://unsplash.com/photos/zYzf8vxsJ6U" rel="noopener noreferrer" target="_blank">Unsplash</a></small></p>
        <img className="pb-1 lozad" width="100%" src="../images/4/fishing.jpg" alt="Drone aerial of boats fishing" />
        <p>
          <small><b>Fishery on the lake</b> (1/400 f/2.2 ISO 100 4.73mm)</small><br />
          <small>Download from: <a className="button-link" href="https://unsplash.com/photos/JRRfBvYK4og" rel="noopener noreferrer" target="_blank">Unsplash</a></small>
        </p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/4/aaron-flower.jpg" alt="Man holding a flower" />
          <p><small><b>Aaron with a gift</b> (1/400 f/1.4 ISO 320 50mm)</small></p>
        </div>

        <img className="pb-1 lozad" width="100%" src="../images/4/aaron-down.jpg" alt="Man crouching in front of a hedge" />
        <p><small><b>Aaron taking macro shots</b> (1/400 f/4.0 ISO 320 17mm)</small></p>
        <img className="pb-1 lozad" width="100%" src="../images/4/single-boat.jpg" alt="Drone aerial of a single boat fishing" />
        <p>
          <small><b>Alone on the lake</b> (1/400 f/2.2 ISO 100 4.73mm)</small><br />
          <small>Download from: <a className="button-link" href="https://unsplash.com/photos/-yYpGC9P_dk" rel="noopener noreferrer" target="_blank">Unsplash</a></small>
          </p>
        <p>For our first camping trip I'd say it was a success. We had great fun and managed to take some amazing photos. We certainly wouldn't have been able to explore and see so much if we hadn't camped.</p>
        <p>Before I go I want to give a shout out to the people at Laneside Caravan Park, they were amazing and I would highly recommend their campsite.</p>
        <img className="lozad" width="100%" src="../images/4/bye.gif" alt="Bye" />
      </div>
    );
  }

export default Project4;
