import React from "react"; 
import '../../../App.css';


function signsOfLife () {

    return (
      <div>
        {/* <MetaTags>
           <title>Matt Feeney Photography</title>
           <meta name="description" content="Matt Feeney Photography - Photo Gallery" />
         </MetaTags> */}
        <section>
          <div id="recent-work-container" className="row justify-content-center">
            <div className="col-10 text-center pt-4">
              <h2>Signs of life</h2>
            </div>
            <img src="../../../images/projects/signs-of-life/coffees.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/left-bikes-and-empty-chairs.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/castle-left-shoes.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/dropped-chips-and-tickets.jpg" className="col-10 col-lg-9 mt-5" width="100%"/> 
            <img src="../../../images/projects/signs-of-life/splatter.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/left-mug.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/left-radio.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/car.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/foot-prints.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/left-scooter.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/left-toy-car.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>        
            <img src="../../../images/projects/signs-of-life/news-paper-window.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/dropped-cone.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
            <img src="../../../images/projects/signs-of-life/two-scooters.jpg" className="col-10 col-lg-9 mt-5" width="100%"/>
          </div>
         </section>
      </div>
    );
  }

export default signsOfLife;
