import React, { Component } from "react";
import '../App.css';
import lozad from 'lozad';

function Project2() {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
        <p>First off, I'm not going to bore you with a super in depth review of all my gear. There's some amazing reviews already out there of everything (or most things) in my bag. I'm also not going to list prices or link to where you can buy any of it from, this post is purely for the people interested in what gear I carry when I head out taking photos.</p>
        <p>Psst... <small>I'm sorry about the quality of the images, I had to use my phone to take photos for this post.</small></p>
        <h5>The meat of my gear, my camera</h5>
        <img className="pb-2 lozad" width="100%" src="../images/2/mycamera.jpg" alt="My Camera" />
        <p>If you read my previous post you might already know, but for those who don't, I shoot with a Canon 7D (Mark I).</p>
        <p>I love my camera, it's not new, I bought it second hand from a friend, but it's been amazing. I've taken it all over, in all kinds of weather conditions and it's never let me down.</p>
        <p>Some brief specs:</p>
        <ul>
          <li>18MP APS-C CMOS sensor</li>
          <li>8 frames per second continuous shooting</li>
          <li>1080p HD video recording with manual controls</li>
          <li>19-point AF system (all cross-type)</li>
          <li>Environmental sealing</li>
        </ul>
        <p>If you want to see a full review of my camera, check out this one by <a className="button-link" href="https://www.dpreview.com/reviews/canoneos7d" rel="noopener noreferrer" target="_blank">DP Review</a>. It's crazy in depth, there's 31 pages!</p>
        <h5>Lens</h5>
        <img className="pb-2 lozad" width="100%" src="../images/2/lens.jpg" alt="My camera lenses" />
        <p>I carry two lenses in my bag:</p>
        <ul>
          <li>Canon EF-S 17-85mm f/4-5.6 IS USM</li>
          <li>Canon EF 50mm f/1.4 USM</li>
        </ul>
        <p>Between the two lenses, I have a lot of situations covered. I have my 17-85mm on my camera most of the time, it works in 80-90% of situations I find myself in. The rest of the time I'm using my 50mm.</p>
        <h5>Drone</h5>
        <img className="pb-2 lozad" width="100%" src="../images/2/drone.jpg" alt="My Drone" />
        <p>To be honest I nearly didn't buy a drone. I wasn't sure if the benefit of a drone would out way the cost. I was stuck between getting a drone and upgrading my camera to the newer Canon 7D (Mark II). I'm glad I went with the drone, buying one has opened up a whole other world of photography to me.</p>
        <p>For those who don't know what drone I have from the photo, it's a DJI Mavic Pro.</p>
        <p>If you're interested in getting a drone, I would recommend any drone from DJI. I've racked up over 8 hours of flight time with my Mavic Pro, covering over 100,000km and I've had no issues. My only suggestion would be to do your research and work out what you need. They have a great range of drones, ranging from a few hundred pounds to thousands.</p>
        <p>I would also recommend that you read up on drone laws and safety. Here's a great guide from <a className="button-link" rel="noopener noreferrer" target="_blank" href="http://dronesafe.uk/wp-content/uploads/2016/11/Dronecode.pdf">DroneSafe</a> for flying drones here in the UK.</p>
        <h5>Lastly... my bag</h5>
        <p><small>I know my bag is not technically inside my bag (well it kind of is), I wanted to include it anyway.</small></p>
        <p>My bag is a Lowepro Photo Hatchback BP 250 AW II</p>
        <p>It's got all the usual bag features: zips, pockets, a water proof cover, but the the main feature which sold me on it was the hidden pocket in the back. I go away on trips with my camera and I feel a lot safer travelling knowing my gear is tucked away.</p>
        <p>If you're after a good all round camera bag, I'd recommend a Lowepro Photo Hatchback. There's a few different colour and size options, also the hidden camera pocket can be customised too!</p>
        <p>And that's it, till next time...</p>
        <img className="pb-2 lozad" width="100%" src="../images/2/bye.gif" alt="Bye" />
      </div>
    );
  }

export default Project2;
