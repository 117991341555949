import * as React from "react";
import {useState} from "react";
import '../../App.css';
import {
  Link,
  NavLink
} from "react-router-dom";
            
function Header (){

  const [menuOpen, setMenuOpen] = useState(false);

      return(
      <div className="">
          <div className="row mb-3 mt-3">
            <header className="col-12 header-container">

                  <div className="row">
                    
                    <div className={`col-1 d-block d-md-none menu-icon  mt-0 pt-0 ${menuOpen ? "show" : ""}` } onClick={ () => setMenuOpen(!menuOpen)}></div>
                    <NavLink className="col-10 col-md-12 text-center pt-2 pb-2 d-block" to="">
                        <img width="200px" className="header-logo" src="../../images/logo-name.png" alt="matt feeney photography logo" />
                    </NavLink>
                  </div>
            </header>
          </div>
            <div id="mobile-menu" className={menuOpen? "show" : ""}>
              <div className="row align-items-center">
                  <NavLink className="col-12 nav-link  " to="../projects/breakfast-food" onClick={ () => setMenuOpen(false)}>
                        BREAKFAST FOOD
                      </NavLink>
                      <NavLink className="col-12 nav-link  " to="../projects/take-me-to-church" onClick={ () => setMenuOpen(false)}>
                        TAKE ME TO CHURCH
                      </NavLink>
                      <NavLink className="col-12 nav-link  " to="../projects/real-wrasslin" onClick={ () => setMenuOpen(false)}>
                        REAL WRASSLIN'
                      </NavLink>
                      <NavLink className="col-12 nav-link  " to="../Blog" onClick={ () => setMenuOpen(false)}>
                        BLOG
                      </NavLink>
                      <a className="col-12 nav-link" target="_blank" href="https://www.instagram.com/matt__feeney/" onClick={ () => setMenuOpen(false)}>
                    INSTAGRAM
                  </a>
                      <NavLink className="col-12 nav-link  " to="../Contact" onClick={ () => setMenuOpen(false)}>
                        CONTACT
                      </NavLink>
                      <NavLink className="col-12 nav-link  " to="../About" onClick={ () => setMenuOpen(false)}>
                        ABOUT
                      </NavLink>
                   
            </div>
          </div>
        </div>
      )}


export default Header;
