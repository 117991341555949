import React, { Component } from "react";
import '../App.css';
import lozad from 'lozad';

function Project8() {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
        <p>Not sure where I the idea came from, but for a little while now I've been challenging myself to go out with only one lens. My only goal... to walk away with atleast <b>one</b> photo I like.</p>
        <div className="row mb-2">
          <div className="col-12 text-center">
             <h5><i>Sounds simple enough?</i></h5>
          </div>
        </div>
        <p>That's where you're wrong kiddo. Making one photo you like isn't always that easy and now on top of that we're now adding extra constraints into the mix.</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/8/barman.jpg" alt="Bar Man" />
          <p><small><b>Bar Man</b> (1/640s f/1.8 ISO 100 50mm)</small></p>
            </div>
        <p>For the challege I tend to use my Sigma 50mm f/1.4 as it's a great lens and a decent focal length, but from time to time I'll mix it up and use my Sigma 16mm f/1.4. Shooting at 16mm (24mm equivalent) can be incredibily difficult, especially when shooting street.</p>
        <div className="row mb-2">
          <div className="col-12 text-center">
             <h5><i>If you don't have any prime lenses don't worry!</i></h5>
          </div>
        </div>
        <p>You don't need a prime lens to take on this challenge, a zoom lens will work just fine. The only down side to using a zoom lens is that your aperture might not be as fast as a prime lens.</p>
        <p>If you decided to use a zoom lens, set your focal length at the start and leaving it. Don't be tempted to and move it, you'll only be cheating yourself!</p>
        <p>For bonus points, try setting your focal length to something you wouldn't normally use. So if your shooting street with a 70-200, try using 200mm. Challenge yourself! You might not take many photos you like, but if you enjoy a challenge you will have a lot of fun.</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="80%" src="../images/8/lost-in-stone.jpg" alt="Lost in stone" />
          <p><small><b>Lost in Stone</b> (1/640s f/1.8 ISO 100 50mm)</small></p>
        </div>
        <p>I didn't start of with a time limit, but I've been inspired by Evan Ranft and his 1 hour photo challenges.</p>
        <p>Combining a time limit was inspired by <a href="https://www.evanranft.com/" target="_blank" title="Evan Ranft">Evan Ranft</a>. Evan would park his car up somewhere and only put an hour on the meter, limiting how long he had for photos.</p>
        <p>My time limit is normally set by how long my girl friend would be in certain shops. Some days I would have maybe 10 minutes, others it would be 20-30. Having such a small window to take photos changes everything!</p>
        <p>I can't sit and fish a spot waiting for a subject, it can waste too much time. It means I have to use my time wisely and almost constantly be on the move.</p>
        <p>sticking with a time limit or prime lens</p>
        <p>forces me to be creative and have to work a bit harder</p>
        <p>combining both a time limit and a prime lens for extra fun</p>
        <p>pairing either or both with street photography can be tricky as you can't sit and fish for shots, you have to constantly be on the more and looking for shots</p>
        <p>you can add an optional third goal to the challenge, applying a theme. Such as match colours or framing subjects through other objects. Some times it can make things a little easier, having a theme in mind and it helps me notice potenial shots</p>
        <p>Examples of themes:</p>
        <p>Colours, framing, patterns, refelctions... </p>
        <p>These are broad, and you can apply theme to most styles of photography</p>
        <p>If you're shooting street, then you can look at themes more relative</p>
        <p>Examples... emotions/feelings (happy, lonely, etc), lights and shadows (one of my favourites), silhouettes, motion</p>
        <p>You would think adding a theme to the constraints would make the challege harder. I mean why wouldn't it, you've got: one lense, 15 minutes and now you can only take shots of reflections? Strangly it make things a lot easier. Think about it, instead of having to constantly scan for any potential shots, you can filter everything out and only focus on looking for reflections.</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/8/rainbow-umbrella.jpg" alt="Rainbow Umbrella" />
          <p><small><b>Rainbow</b> (1/640s f/1.8 ISO 100 50mm)</small></p>
        </div>
        <p>Examples of trying to match the colour of the subject in their environment</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/8/seeing-yellow.jpg" alt="Seeing Yellow" />
          <p><small><b>Seeing Yellow</b> (1/640s f/1.8 ISO 100 50mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/8/red-pedestrians.jpg" alt="Pedestrians this way" />
          <p><small><b>Pedestrians this way</b> (1/640s f/1.8 ISO 100 50mm)</small></p>
        </div>
        <p>Examples of taking photos with framing in mind</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/8/together.jpg" alt="Together" />
          <p><small><b>Together</b> (1/640s f/1.8 ISO 100 50mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/8/bnw-tunnel.jpg" alt="Together" />
          <p><small><b>bnw Tunnel</b> (1/640s f/1.8 ISO 100 50mm)</small></p>
        </div>

        <p>Doesn't have to be a 50mm lens, I've been out with a 16mm prime lens</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/8/framed-cathedral.jpg" alt="Together" />
          <p><small><b>Framed Cathedral</b> (1/2500s f/1.6 ISO 100 16mm)</small></p>
        </div>
        <p>You can also go out at night, it doesn't just have to be during the day</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/8/burger-king.jpg" alt="Together" />
          <p><small><b>Hail to the king</b> (1.6s f/7.1 ISO 100 16mm)</small></p>
        </div>
        <p>Make up for not having a zoom, by either using your feet or by create a panorama.</p>
        <p>Getting creative using a 16mm lens to stitch shots in post to create a pan. The same idea could be used with different prime lenses to get round limitations.</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/8/prague.jpg" alt="Together" />
          <p><small><b>Prague</b> (1/1000s f/4.0 ISO 100 16mm)</small></p>
        </div>

        <p>Wrap up</p>
        <p>Trying new things, so if you onlu shoot during the day, try get out and shoot at night. Mix it up!</p>
        <p>The idea behind these techniques is to try get out of your comfort zone and to experiment with new ideas.</p>
        <p>It can be easy to get into a routine, shooting the same things in the same way. Having all the gear to make take those photos really easy.</p>
        <p>Leave everything at home, except one camera body and one prime lense. If you don't have a prime lens, then bring a zoom lens, but leave it at one focal length and don't move it for the whole time you're out!</p>
      </div>
    );
  }

export default Project8;
