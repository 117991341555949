import React from "react";

import Project1 from "../project1";
import Project2 from "../project2";
import Project3 from "../project3";
import Project4 from "../project4";
import Project5 from "../project5";
import Project6 from "../project6";
import Project7 from "../project7";
import Project8 from "../project8";
import Project9 from "../project9";
import Project10 from "../project10";
import Project11 from "../project11";
import Project12 from "../project12";
interface Props{
  project:string
}

const BlogPostList : React.FC<Props> = ({
  project
}: Props) =>{

  //Switch project to be a number and can then use that as an index to grab it from an array?
  //Still not the best idea, but would be more robust for now
  if(project === "Project1"){
    return <Project1 />
  }
  if(project === "Project2"){
    return <Project2 />
  }
  if(project === "Project3"){
    return <Project3 />
  }
  if(project === "Project4"){
    return <Project4 />
  }
  if(project === "Project5"){
    return <Project5 />
  }
  if(project === "Project6"){
    return <Project6 />
  }
  if(project === "Project7"){
    return <Project7 />
  }
  if(project === "Project8"){
    return <Project8 />
  }
  if(project === "Project9"){
    return <Project9 />
  }
  if(project === "Project10"){
    return <Project10 />
  }
  if(project === "Project11"){
    return <Project11 />
  }
  if(project === "Project12"){
    return <Project12 />
  }
  return <div />
}
export default BlogPostList;
