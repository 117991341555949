import React, { Component } from "react";
import '../App.css';
import lozad from 'lozad';

function Project7(){
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    return (
      <div className="pt-3">
        <p>What a year... 166 photos uploaded to instagram, entered my first photography competitions (and won a couple), I shot my first ever event and lots of other good stuff happened!</p>
        <p>My main goal for 2018 was to challenge myself and push to try new things, getting out of my comfort zone. Ultimately this led me on a path towards taking up street photography. Looking back I've come a long way in 12 months and I'm excited to see where 2019 is going to take me.</p>
        <p>To wrap up 2018, here's some of my favourite photos from the last 12 months:</p>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/7/snow.jpg" alt="Lincoln in Snow" />
          <p><small><b>1. Snow Day</b> (1/640 f/2.2 ISO 100 4.73mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="100%" src="../images/7/draining.jpg" alt="Ladybower Draining" />
          <p><small><b>2. The Abyss</b> (1/800 f/2.2 ISO 100 4.73mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/7/sparks.jpg" alt="Sparks" />
          <p><small><b>3. Sparks</b> (1/10 f/14 ISO 1000 50mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="80%"src="../images/7/brayford-reflection.jpg" alt="Brayford Reflection" />
          <p><small><b>4. Brayford Reflection</b> (1/800 f/2.2 ISO 100 4.73mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="80%" src="../images/7/waiting-game.jpg" alt="Waiting Game" />
          <p><small><b>5. Waiting Game</b> (1/800 f/2.2 ISO 100 4.73mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/7/dishes.jpg" alt="RAF Dishes" />
          <p><small><b>6. RAF Stenigot</b> (57s f/16 ISO 100 19mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/7/sun-set-colours.jpg" alt="Sun Set Colours" />
          <p><small><b>7. Sunset Reflections</b> (1/100s f/4.5 ISO 100 35mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/7/ritz.jpg" alt="Night at the Ritz" />
          <p><small><b>8. Night at the Ritz</b> (2.5s f/11 ISO 100 18mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="80%" src="../images/7/tunnel.jpg" alt="Tunnel Vision" />
          <p><small><b>9. Tunnel Vision</b> (1/800 f/2.8 ISO 500 23mm)</small></p>
        </div>
        <div className="text-center">
          <img className="pb-1 lozad" width="60%" src="../images/7/zoom-exposure.jpg" alt="Seeing Double" />
          <p><small><b>10. Seeing Double</b> (4s f/9.0 ISO 100 35mm)</small></p>
        </div>
        <p>I pushed myself to try a lot of new things and I'm glad I did (even with failing more than I would like). I feel I've become a stronger photographer and I have some amazing photos to show for all my hard work.</p>
        <p>I would seriously recommend mixing things up and trying to take different photos. You can try shoot a different gerne, or even just try to shoot in black and white. Challenging yourself creatively can be great fun and can be a good way to improve your skills.</p>
        <p>Right... time to crack on with 2019!</p>
        <img className="pb-2 lozad" width="100%" src="../images/7/bye.gif" alt="Bye" />
      </div>
    );
  }

export default Project7;
